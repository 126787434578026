/** Class for representing a single item within a schedule.
 */
import IntervalPeriod from 'helpers/IntervalTime/IntervalPeriod'
import ExactPeriod from 'helpers/ExactPeriod/ExactPeriod'
import {v4 as uuidv4} from 'uuid'
import moment from 'moment'
import {serializeEndlessDate} from 'helpers/schedule_helpers'

export default class ScheduleItem {

  constructor(item, container, {newKey = false} = {}) {
    // If the item passed is already a ScheduleItem, make this ScheduleItem a clone of item
    if(item instanceof ScheduleItem) {
      return this.copyOf(item, {newKey})
    }
    // Doing this destructure so that I can get any extra unexpected keys and map them to the extraKeys parameter
    let {item: fpath,
      guid,
      in: itemIn,
      out,
      loop,
      start,
      end,
      lock,
      block,
      label,
      'error status': errorStatus,
      'item duration': itemDuration,
      'event id': eventId,
      association,
      'association name': associationName,
      metadata,
      'scte-35': scte35,
      ...extraKeys} = item
    let {scheduleType: type, intervalDuration, intervalBasis} = container
    let span;
    this.valid = true;
    try {
      if(type === "interval") {
        span = new IntervalPeriod(start, end, type, {days: intervalDuration, basis: intervalBasis}, {zeroOk: true})
      } else if(type === "endless") {
        span = new ExactPeriod(start, end)
      } else {
        span = new IntervalPeriod(start, end, type, undefined, {zeroOk: true})
      }
    } catch (err) {
      console.error(err)
      this.valid = false;
    }
    this.type = 'item'
    this.span = span
    this.body = {
      label: parseItemName(fpath)
    }
    this.originalItem = fpath
    this.guid = guid
    this.in = itemIn
    this.out = out
    this.label = label
    this['error status'] = errorStatus
    this.loop = -1
    if (loop !== undefined) {
      if (typeof(loop) == 'string' && loop.match(/^\d+$/)) this.loop = parseInt(loop)
      else this.loop = loop
    }
    this.lock = lock
    this.block = block ? block : null
    this.scte35 = scte35
    this.key = uuidv4()
    this.extraKeys = extraKeys
    if(association || associationName) {
      this.association = {
        id: association,
        name: associationName
      }
    }
    if(this.association) {
      this.body.label = `${this.body.label} with ${this.association.name} [${this.association.id}]`
    }
    if(itemDuration) {
      this['item duration'] = itemDuration
    }
    if(metadata) {
      this.fileMetadata = metadata
    }
    if(eventId) {
      this.type = "event"
      this.eventId = eventId
    }
  }

  /**
   * Sets this ScheduleItem to be a copy of a given ScheduleItem
   * @param {object} item The ScheduleItem to make this a copy of
   * @returns this
   */
  copyOf(item, {newKey = false} = {}) {
    if(!(item instanceof ScheduleItem)) {
      throw new Error(`Trying to make a ScheduleItem into a copy of something that isn't a schedule item: ${item}. ScheduleItem.copyOf only accepts other ScheduleItems.`);
    }
    // Copy from item
    Object.keys(item).forEach((key) => {
      if(key === "span") {
        if(item[key] instanceof ExactPeriod) {
          this[key] = new ExactPeriod(item[key].start, item[key].end)
        } else {
          this[key] = new IntervalPeriod(item[key].start, item[key].end, item[key].period, item[key].intervalDuration, {zeroOk: true})
        }
      } else if(item[key] instanceof Array) {
        this[key] = [...item[key]]
      } else if(typeof item[key] === "object" && item[key] !== null) {
        this[key] = {...item[key]}
      } else {
        this[key] = item[key]
      }
    })
    if(newKey) {
      this.key = uuidv4()
    }
    return this
  }

  /**
   * Returns a copy of this ScheduleItem
   */
  clone({newKey = false} = {}) {
    return new ScheduleItem(this, null, {newKey})
  }

  /**
   * Changes the start time of this ScheduleItem, without changing the length. Note any parent container will NOT
   *  be sorted after using this method; you should call sortByTime on the parent after this method.
   * @param {object} time The new start time of this ScheduleItem; can be an IntervalTime, or anything
   *  that can be passed to the constructor of IntervalTime
   * @param {object} opts Optional arguements
   * @param {boolean} opts.endAtTime Defaults to false. If true, the item will end at the given time instead of starting at it
   *  moving multiple children at once)
   * @returns this
   */
  moveToTime(time, opts={}) {
    let {endAtTime=false} = opts
    if(endAtTime) {
      this.span = this.span.moveToEnd(time)
    } else {
      this.span = this.span.moveToStart(time)
    }
    return this
  }

  /**
   * Changes the start/end time of this
   * @param {object/string} start The new start time for this. Can be anything accepted by the constructor of IntervalTime. If nothing is passed,
   *  start time will not be changed
   * @param {object/string} end The new end time for this. Can be anything accepted by the constructor of IntervalTime. If nothing is passed,
   *  end time will not be changed
   * @param {object} opts Optional arguements
   * @param {boolean} opts.parentSort Defaults to true. If true, parent container will be sorted by time after time of this is changed.
   */
  /*
  alterTime(start, end, opts={}) {
    let {parentSort=true} = opts
    let type = this.span.start.period
    let intervalData = this.span.start.intervalDuration
    if(start) {
      start = new IntervalTime(start, type, intervalData)
    } else {
      start = this.span.start
    }
    if(end) {
      end = new IntervalTime(end, type, intervalData)
    } else {
     end = this.span.end
    }
    this.span = new IntervalPeriod(start, end);
    if(parentSort && this.parent && this.parent.sortByTime) {
      this.parent.sortByTime()
    }
    return this
  }
  */

  /**
   * Returns an object that can be used for serializing this schedule item to be added to a schedule file
   */
  serializeToObject() {
    let extraKeys = this.extraKeys ? this.extraKeys : []
    let errorStatus = this['error status']
    if(this.assigned && (this.assigned.play || this.assigned.upload || this.toCopy)) {
      errorStatus = ""
    }
    // Handle moment start/end
    let start, end
    if(moment.isMoment(this.span.start)) {
      start = serializeEndlessDate(this.span.start)
    } else {
      start = this.span.start.toScheduleString()
    }
    if(moment.isMoment(this.span.end)) {
      end = serializeEndlessDate(this.span.end)
    } else {
      let next = this.span.start.diff(this.span.end) > 0 ?
        " next" :
        ""
      end = this.span.end.toScheduleString() + next
    }
    let toReturn = {
      ...extraKeys,
      item: this.originalItem,
      'item duration': this['item duration'],
      'in': this.in,
      out: this.out,
      loop: (this.loop !== undefined && this.loop !== "" && this.loop >= 0) ? (this.loop ? 1 : 0) : null,
      guid: this.guid,
      locked: this.lock ? 'true' : null,
      start,
      end,
      label: this.label,
      'error status': errorStatus,
      'scte-35': this.scte35
    }
    if(this.block !== null) {
      toReturn.block = this.block
    }
    if(this.type === 'event') {
      toReturn['event id'] = this.body.label
    }
    if(this.association) {
      toReturn.association = this.association.id
      toReturn["association name"] = this.association.name
    }
    Object.entries(toReturn).forEach(([key, val]) => {
      if(val === null || val === undefined) {
        delete toReturn[key]
      }
    })
    return toReturn
  }

}

/**
 *  Helper function to extract the filename of a schedule item from its full path
 *  @param {string} rawName The full path of the item
 *  @returns filename of the item as a string
 */
export const parseItemName = (rawName) => {
  if(!rawName) {
    return ""
  }
  if(typeof rawName === 'string') {
    rawName = rawName.split('/')
  }
  return rawName[rawName.length - 1]
}
