/*
Steps for upload:
1. POST to create the upload and receive upload id
2. HEAD uploaded file.
3. If size of uploaded file === size of local file, we are done.
4. If size of uploaded file < size of local file, create a PUT request with a content-range header and seek to the appropriate location in the file
5. If connection fails during PUT, go to step 2.
*/
import {fetchFromServer, fetchFileFromServer} from 'helpers/net_helpers'

const wait = async (time=0) => {
  return new Promise((resolve) => {setTimeout(resolve, time)})
}

export default class FileUploader {

  /**
  * @param {File} file The file to be uploaded
  * @param {string} dest The destination to upload the file to
  * @param {object} options Optional arguments
  * @param {string} options.form Upload consent form to go with the upload when creating the job, serialized to a string
  * @param {string} options.template String path of a metadata template to apply to the uploaded file
  * @param {object} options.sendToVod Auto send to vod options to include in the POST request
  */
  constructor(file, dest, options={}) {
    if(!file || !(file instanceof File)) {
      throw new Error("When creating a FileUploader, you must pass in a File to upload as the first argument to the constructor.")
    }
    if(!dest || typeof dest !== "string") {
      throw new Error("When creating a FileUploader, you must pass in a string destination as the second argument to the constructor.")
    }
    this.file = file;
    this.destination = dest;
    this.options = options;
  }

  async createUploadJob() {
    let body = {
      size: this.file.size,
      destination: this.destination,
      filename: this.file.name
    }
    if(this.options.form) {
      body.form = this.options.form
    }
    if(this.options.template) {
      body.metadataTemplate = this.options.template
    }
    if(this.options.sendToVod) {
      body.sendToVod = this.options.sendToVod
    }
    let id = await fetchFromServer('/v2/uploads/upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if(!id.ok) {
      let err = await id.text()
      console.error(`Error creating upload job: ${err}`)
      throw new Error(`Error creating upload job: ${err}`)
    }
    this.uploadId = await id.text()
    this.abortController = new AbortController();
    return this.uploadId;
  }

  async uploadFile() {
    if(!this.uploadId) {
      console.error("No upload id for this upload. createUploadJob must be called before uploadFile can be called.")
      return
    }
    try {
      // HEAD uploading file
      let uploadedSize = 0
      let headRes = await fetchFileFromServer(`${this.destination}/${this.file.name}.tmp`, {method: 'HEAD', signal: this.abortController.signal})
      if(!headRes.ok) {
        if(headRes.status === 404) {
          // 404 is ok, that just means we haven't started the upload yet. But just to be sure, check to see if the file already exists too...
          headRes = await fetchFileFromServer(`${this.destination}/${this.file.name}`, {method: 'HEAD', signal: this.abortController.signal})
          if(headRes.ok) {
            let fileSize = parseInt(headRes.headers.get("Content-Length") || "0", 10)
            if(isNaN(uploadedSize)) {
              throw new Error(`When trying to head the in progress upload of file upload to ${this.destination}, the Content-Length header received was parsed to NaN.`
                + ` Content Length header was: ${headRes.headers.get("Content-Length")}`)
            }
            if(fileSize === this.file.size) { // Upload was already finished.
              return;
            }
          }
        } else if(headRes.status === 408 || headRes.status === 503 || headRes.status === 504) {
          // Upload timeout? Try again after a delay.
          await wait(1000);
          return this.uploadFile()
        } else {
          let errMsg = await headRes.text();
          console.error(`Error checking uploaded file. Server responded with ${headRes.status} ${headRes.statusText}:`)
          console.error(errMsg)
          throw new Error(`Upload failed with status code ${headRes.status} ${headRes.statusText}: ${errMsg}`)
        }
      } else {
        uploadedSize = parseInt(headRes.headers.get("Content-Length") || "0", 10)
        if(isNaN(uploadedSize)) {
          throw new Error(`When trying to head the in progress upload of file upload to ${this.destination}, the Content-Length header received was parsed to NaN. Content Length header was: ${headRes.headers.get("Content-Length")}`)
        }
      }
      // If uploaded file size === local file size, we are done.
      if(uploadedSize === this.file.size) {
        return;
      }
      // If uploaded file size < local file size, we need to add a content-range header and seek to the appropriate place in the file (unless size of uploaded file is zero or we get a 404)
      // Create and send PUT request
      let sendData = this.file
      let headers = {}
      if(uploadedSize > 0) {
        let part = this.file.slice(uploadedSize)
        sendData = new File([part], this.file.name, {type: this.file.type})
        headers["Content-Range"] = `bytes ${uploadedSize}-${this.file.size}/${this.file.size}`
      }
      // Don't start the upload if we aborted already.
      if(this.aborted) {
        return;
      }
      let destPath = this.destination;
      if(!destPath.startsWith("/")) {
        destPath = "/" + destPath
      }
      // Special case for text and json, to prevent body parser from trying to parse them
      if(this.file.type === "text/plain" || this.file.type === "application/json") {
        headers["Content-Type"] = "application/octet-stream"
      }
      let res = await fetchFromServer(`/v2/uploads/uploadV2/${this.uploadId}`, {
        method: 'PUT',
        body: sendData,
        signal: this.abortController.signal,
        headers
      })
      if(res.ok || this.aborted) {
        return;
      } else if(res.status === 408 || res.status === 503 || res.status === 504) {
      // If PUT request experiences a network failure, try again after a short delay
        await wait(1000)
        return this.uploadFile()
      } else {
        let errMsg = await res.text();
        console.error(`Upload failed with status code ${res.status} ${res.statusText}: ${errMsg}`)
        throw new Error(`Upload failed with status code ${res.status} ${res.statusText}: ${errMsg}`)
      }
    } catch (err) {
      if(err.name === "TypeError" && err.message.startsWith("NetworkError")) {
        await wait(1000)
        return this.uploadFile()
      } else {
        throw err
      }
    }
  }

  abort() {
    this.aborted = true
    if(!this.abortController) {
      return
    }
    this.abortController.abort();
  }

}
