import React, {Component} from 'react'
import moment from 'moment'
import {timeFormat} from 'helpers/schedule_helpers'
import {Table, Checkbox, Icon, Image, Popup, List, Label} from 'semantic-ui-react'
import ScheduleItemDropdown from './ScheduleItemDropdown'
import ScheduleItemErrorButtons from './ScheduleItemErrorButtons'
import {millisToHourString} from 'helpers/general_helpers'
import {fileTypeInfo, getContentWindowStatus} from 'helpers/library_helpers'
import {staticAsset} from 'helpers/net_helpers'
import isEqual from 'lodash.isequal'

const updateProps = [
  'span',
  'body',
  'type',
  'selected',
  'blockSelected',
  'blockColor',
  'className',
  'errors',
  'clipboard',
  'inBlock',
  'loop',
  'lock',
  'closerToBottom',
  'showSubseconds',
  'error status',
  'playingOn',
  'scrollEvent',
  'label'
]

class ScheduleTableItem extends Component {

  constructor(props) {
    super(props)
    this.state = {shouldDeselect: false}
    this.timeRef = null
    this.bodyRef = null
    this.setTimeRef = element => {
      this.timeRef = element
    }
    this.setBodyRef = element => {
      this.bodyRef = element
    }
  }

  shouldComponentUpdate(nextprops) {
    for(let key of updateProps) {
      if(key === 'body') {
        if(this.props[key].label !== nextprops[key].label) {
          console.log(key)
          return true
        }
      } else if(key === 'span') {
        if((this.props[key].start.valueOf() !== nextprops[key].start.valueOf()) ||
          (this.props[key].end.valueOf() !== nextprops[key].end.valueOf())) {
          console.log(key)
          return true
        }
      } else if(key === 'scrollEvent') {
        if(nextprops[key].id &&
          nextprops[key].time &&
          nextprops.span.contains(nextprops[key].time)) {
          console.log(key)
          return true
        }
      } else if(key === "errors") {
        if(this.props[key] && nextprops[key] && nextprops[key].join((err) => err.type) !== this.props[key].join((err) => err.type)) {
          console.log(key)
          return true
        } else if (!!this.props[key] !== !!nextprops[key]) {
          console.log(key)
          return true
        }
      } else if(!isEqual(this.props[key], nextprops[key])) {
        console.log(key)
        return true
      }
    }
    return false
  }

  componentDidMount() {
    this.handleScrollSelfIntoView()
  }

  componentDidUpdate() {
    this.handleScrollSelfIntoView()
  }

  handleScrollSelfIntoView = () => {
    if(this.props.scrollEvent.id &&
      this.props.scrollEvent.time &&
      this.props.span.contains(this.props.scrollEvent.time) &&
      this.timeRef) {
      this.timeRef.scrollIntoView({block: 'center'})
      this.props.itemActions.finishTimeScroll()
    }
  }

  addItems = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let {type,
      span,
      addScheduleItems,
      pasteScheduleItems,
      hasSelectedFiles} = this.props
    if(type === 'empty') {
      if(hasSelectedFiles) {
        addScheduleItems(span.start)
      } else {
        pasteScheduleItems(span.start)
      }
    }
  }

  handleInsertHere = () => {
    this.props.itemActions.insertIntoSchedule(this.props.span.start)
  }

  assignItemToEvent = () => {
    this.props.itemActions.assignSelectedItemToEvent(this.props.guid)
  }

  handleSelect = (e) => {
    let {scheduleItem, selectScheduleItems} = this.props
    let toSelect = scheduleItem.key
    let options = {}
    if(e.shiftKey) {
      options = {range: true, method: 'select'}
    }
    selectScheduleItems([toSelect], options)
  }

  handleDropdownSelect = () => {
    let {scheduleItem, selectScheduleItems, type, selected} = this.props
    if(type === 'empty' || selected) {
      return
    }
    let toSelect = scheduleItem.key
    this.setState({shouldDeselect: true})
    selectScheduleItems([toSelect], {method: 'select'})
  }

  handleDropdownDeselect = () => {
    let {scheduleItem, selectScheduleItems, type, selected} = this.props
    if(type === 'empty' || !selected || !this.state.shouldDeselect) {
      return
    }
    let toSelect = scheduleItem.key
    this.setState({shouldDeselect: false})
    selectScheduleItems([toSelect], {method: 'deselect'})
  }

  handleKeyShortcuts = (e) => {
    let {copyScheduleItems, moveScheduleItems} = this.props.itemActions
    if(e.ctrlKey) {
      if(e.key === 'c') {
        copyScheduleItems()
      } else if(e.key === 'x') {
        moveScheduleItems()
      }
    }
  }

  handleShiftItem = (direction) => {
    let {scheduleItem, showSubseconds} = this.props
    let {shiftItem, messages} = this.props.itemActions
    messages.prompt('How far do you want to push this item?', (timeToShift) => {
      if(timeToShift === null) {
        return
      }
      shiftItem(timeToShift.asMilliseconds(), direction, scheduleItem.key)
    }, {type: 'duration', subseconds: showSubseconds})
  }

  handleCollapseScheduleGap = () => {
    let {scheduleItem, itemActions} = this.props
    itemActions.collapseScheduleGap(scheduleItem.key)
  }

  handleChangeItemDuration = () => {
    let {scheduleItem, itemActions, span, showSubseconds} = this.props
    let oldDuration = moment.utc(span.end.diff(span.start)).format(timeFormat(showSubseconds, true)[1])
    let toChange = scheduleItem.key
    itemActions.messages.prompt('How long should this timeslot be?', (newDuration) => {
      if(newDuration === null) {
        return
      }
      itemActions.changeScheduleItemDuration(toChange, newDuration)
    }, {type: 'duration', subseconds: showSubseconds, initialValue: oldDuration})
  }

  handleFitSlotToItem = () => {
    let {itemActions, scheduleItem} = this.props
    let itemDuration = this.props['item duration']
    if(!itemDuration) {
      return
    }
    let toChange = scheduleItem.key
    itemDuration = parseFloat(itemDuration * 1000)
    itemActions.changeScheduleItemDuration(toChange, itemDuration)
  }

  handleMoveToTimeslot = () => {
    let {itemActions, type, span, showSubseconds} = this.props
    if(type === "empty") {
      return
    }
    itemActions.messages.prompt('What time should the selected items be moved to?', (timeToPaste) => {
      if(timeToPaste === null) {
        return
      }
      let {itemActions} = this.props
      let timeObject = span.start
      if(!(moment.isMoment(timeObject))) {
        timeObject = timeObject.closestMoment(timeToPaste)
      }
      let {years, months, date} = timeObject.toObject()
      timeToPaste.set({years, months, date})
      this.handleDropdownSelect()
      itemActions.moveSelectedToTime(timeToPaste)
    }, {type: 'time', subseconds: showSubseconds})
  }

  handleCreateBlock = () => {
    let {itemActions, span, showSubseconds} = this.props
    itemActions.messages.prompt("What should the block be called?", (newName) => {
      if(newName === null) {
        return
      }
      itemActions.messages.prompt('How long should this block be?', (newDuration) => {
        if(newDuration === null) {
          return
        }
        itemActions.createScheduleBlock(newName, span.start, newDuration)
      }, {type: 'duration', subseconds: showSubseconds})
    })
  }

  handleCreateTimeslot = () => {
    let {itemActions, showSubseconds} = this.props
    itemActions.messages.prompt('When should this timeslot be?', (slotTime) => {
      if(slotTime === null) {
        return
      }
      itemActions.addEmptyTimeslot(slotTime)
    }, {type: 'time', subseconds: showSubseconds})
  }

  handleReplace = () => {
    let {itemActions, scheduleItem} = this.props
    itemActions.replaceItem(scheduleItem.key)
  }

  handleReplaceAll = () => {
    let {itemActions, scheduleItem} = this.props
    itemActions.replaceAllInstances(scheduleItem.key)
  }

  handleNavigateToLibrary = (openApplication, originalItem) => {
    let path = originalItem.split('/')
    path = path.join('/')
    openApplication('Library', path)
  }

  handleEditLabel = () => {
    let {scheduleItem, itemActions, type, label} = this.props
    if(type === "empty") {
      return
    }
    itemActions.editScheduleItemLabel(scheduleItem.key, label)
  }

  handleLabelClick = (e) => {
    e.stopPropagation()
    this.handleEditLabel()
  }

  handleDurationClick = (e) => {
    if(this.props.type === "empty") {
      this.addItems(e)
    } else {
      this.handleChangeItemDuration()
    }
  }

  handleTimeClick = (e) => {
    if(this.props.type === "empty") {
      this.addItems(e)
    } else {
      this.handleMoveToTimeslot()
    }
  }

  handleItemClick = (e) => {
    if(this.props.type === "empty") {
      this.addItems(e)
    } else {
      if(e.nativeEvent.shiftKey) {
        this.handleReplaceAll()
      } else {
        this.handleReplace()
      }
    }
  }

  render() {
    let {span,
      body,
      type,
      selected,
      originalItem,
      label,
      className='',
      errors,
      clipboard,
      itemActions,
      inBlock,
      selectScheduleItems,
      lock,
      loop,
      fileMetadata,
      showSubseconds,
      playingOn,
      scrollSize,
      hasSelectedFiles,
      blockSelected,
      blockColor,
      scte35,
      scheduleItem} = this.props

    let displayName = body.label

    // Setting up some table contents
    let timeTitle = span.printStartTime({showMilliseconds: showSubseconds})
    let timeLabel = <span key='start' className='startTimeLabel'>
      {timeTitle}
    </span>

    // defaults
    if (lock === undefined || lock < 0) lock = false
    else lock = (lock > 0)

    if (loop === undefined || loop < 0) loop = true
    else loop = (loop > 0)

    // Create lock icon to display if the item is locked, as well as the text
    //  for the lock/unlock items dropdown option
    let lockedIcon = ''
    let lockText = 'Lock Items'
    let lockBtnIcon = 'lock'
    if(lock) {
      lockedIcon = <Icon title='Item is locked' name='lock'/>
      lockText = 'Unlock Items'
      lockBtnIcon = 'unlock'
    }

    let loopIcon = ''
    let loopText = 'Loop Item'
    let loopBtnIcon = 'undo'
    if(loop) {
      loopIcon = <Icon title='Item will loop' name='undo'/>
      loopText = "Play Item Once"
      loopBtnIcon = 'step forward'
    }

    let fileType = ""
    if(fileMetadata && "file type" in fileMetadata) {
      fileType = fileMetadata["file type"]
    }

    let scte35Label = ""
    if(scte35) {
      let details = []
      for(let pair of scte35.split(",")) {
        let [key, val] = pair.split("=")
        if(!val) {
          continue
        }
        if(key === "outofnetwork" && parseInt(val, 10) > 0) {
          details.push("Out of Network")
        }
        if(key === "eventid") {
          details.push(`Event ID: ${val}`)
        }
      }
      scte35Label = <Popup trigger={
        <Label size="mini"
          horizontal
          color="blue">
          SCTE35
          <Label.Detail className="scte35Detail">SPLICE</Label.Detail>
        </Label>}
        content={<List items={details}/>}
        header={"Splice Event"}
      />
    }

    // Create event icon if the item is an event
    let fileIcon = ''
    let eventStatus = null
    if(type === 'event') {
      let {assigned} = this.props
      if(assigned && assigned.toCopy) {
        fileIcon = <Icon title={`Assigned but not saved: /${assigned.toCopy.join("/")}`} name='calendar' color='orange'/>
        eventStatus = (<Label horizontal key="assigned" color="orange" title={`Assigned but not saved: /${assigned.toCopy.join("/")}`}>To Assign<Label.Detail>{`/${assigned.toCopy.join("/")}`}</Label.Detail></Label>)
      } else if(assigned && assigned.play) {
        let assignedItem = assigned.play["original filename"] || "unknown"
        fileIcon = <Icon title={`Will Play: ${assignedItem}`} name='calendar' color='green'/>
        eventStatus = (<Label horizontal key="play" color="green" title={`Will Play: ${assignedItem}`}>Play<Label.Detail>{`${assignedItem}`}</Label.Detail></Label>)
      } else if(assigned && assigned.upload && Object.keys(assigned.upload).length) {
        let uploadFiles = Object.entries(assigned.upload)
        if(uploadFiles.length > 1) {
          fileIcon = <Icon title="Multiple files waiting to be transfered from Upload to Play folder" name="calendar" color="blue"/>
          eventStatus = (<Label horizontal key="upload" color="blue" title="Multiple files waiting to be transfered from Upload to Play folder">Queued<Label.Detail>(multiple)</Label.Detail></Label>)
        } else if (uploadFiles[0]) {
          let filename = uploadFiles[0][0]
          fileIcon = <Icon title={`Waiting to move to Play folder: ${filename}`} name="calendar" color="blue"/>
          eventStatus = (<Label horizontal key="upload" color="blue" title={`Waiting to move to Play folder: ${filename}`}>Queued<Label.Detail>{`${filename}`}</Label.Detail></Label>)
        }
      } else if (assigned && assigned.copying) {
        let filename = assigned.copying
        fileIcon = <Icon title={`Copying to Upload folder: ${filename}`} name="calendar" color="blue"/>
        eventStatus = (<Label horizontal key="upload" color="blue" title={`Copying to Upload folder: ${filename}`}>Copying<Label.Detail>{`${filename}`}</Label.Detail></Label>)
      } else {
        fileIcon = <Icon title='Empty Event' name='calendar' color='red'/>
      }
      /*
      if(this.props['error status'] === 'ENOENT') {
        fileIcon = <Icon title='Empty Event' name='calendar' color='red'/>
      } else if(this.props['error status'] === 'TO COPY') {
        fileIcon = <Icon title='Item Assigned But Not Saved' name='calendar' color='orange'/>
      } else {
        fileIcon = <Icon title='Filled Event' name='calendar' color='green'/>
      }
      */
    } else if(type === 'item') {
      let info = fileTypeInfo(fileType)
      if(info && info.icon) {
        fileIcon = <Icon className='svg-icon' title={info.display} size="large">
            <Image src={staticAsset(`/icons/${info.icon}.svg`)}/>
          </Icon>
      }
    }

    // Set up the dropdown options
    // If the item is empty add paste dropdown option
    let dropdownOptions = []
    if(hasSelectedFiles || (clipboard && clipboard.length)) {
      dropdownOptions.push({key: "insert here", text: "Insert Here", icon: "add", onClick: this.handleInsertHere})
    }
    if(type === 'empty') {
      dropdownOptions = [
        ...dropdownOptions,
        {key:'paste items', text:'Paste Items', icon:'paste', onClick:(() => {itemActions.pasteClipboardItems(span.start)}), disabled: !clipboard.length},
      ]
    // Otherwise set up the dropdown options here
    } else {
      dropdownOptions = [
        ...dropdownOptions,
        {key:'remove items', text:'Remove Items', icon:'remove', onClick:()=>{itemActions.removeScheduleItems()}},
        {key:'replace item', text:'Replace Item', icon:'recycle', onClick:this.handleReplace},
        {key:'replace all', text:'Replace All Instances', icon:'recycle', onClick:this.handleReplaceAll},
        {key:'cut items', text:'Cut Items', icon:'cut', onClick:() => {itemActions.moveScheduleItems()}},
        {key:'copy items', text:'Copy Items', icon:'copy', onClick:() => {itemActions.copyScheduleItems()}},
        {key:'move to timeslot', text:'Move To Timeslot', icon:'share', onClick:this.handleMoveToTimeslot},
        {key:'replicate to times', text:'Replicate To Times', icon:'calendar plus', onClick: itemActions.openReplicateModal},
        {key:'push up', text:'Push Item Backwards', icon:'arrow circle up', onClick:(() => {this.handleShiftItem(true)})},
        {key:'push down', text:'Push Item Forwards', icon:'arrow circle down', onClick:(() => {this.handleShiftItem(false)})},
        {key:'close gap', text:'Close Schedule Gap', icon:'compress', onClick:(() => {this.handleCollapseScheduleGap()})},
        {key:'edit label', text:'Edit Label', icon: 'edit', onClick:(() => {this.handleEditLabel()})},
        {key:'toggle lock', text:lockText, icon:lockBtnIcon, onClick:(() => {itemActions.toggleScheduleItemLock(!lock)})},
        {key:'toggle loop', text:loopText, icon: loopBtnIcon, onClick:(() => {itemActions.toggleItemLoop(!loop)})},
        {key:'go to file', text:'Locate File', icon:'search', onClick:(() => {itemActions.navigateToFile(originalItem)})},
      ]
      // Option exclusive to non-events
      if(type === 'item') {
        dropdownOptions.push({key:'change duration', text:'Change Duration', icon:'clock', onClick:(() => {this.handleChangeItemDuration()})})
      }
      // Option exclusive to events
      if(type === 'event') {
        dropdownOptions.unshift({key:'assign item', text: 'Assign Item', icon:'add', onClick:(() => {this.assignItemToEvent()})})
      }
      if(scte35) {
        dropdownOptions.push({key:'remove scte35 event', text: 'Remove SCTE-35 Event', icon:'', onClick:(() => {itemActions.setItemSCTE35("")})})
      } else {
        dropdownOptions.push({key:'scte35 splice event', text: 'SCTE-35 Splice Event', icon:'', onClick:(() => {itemActions.setItemSCTE35("outofnetwork=1")})})
      }
    }

    // Dropdown option exclusive to all items (empty or otherwise) that are not in a block
    if(!inBlock) {
      dropdownOptions.push({key:'create block', text:'Create Schedule Block', icon:'browser', onClick:this.handleCreateBlock})
    }

    // Dropdown option included for all items (empty or otherwise)
    dropdownOptions.push({key:'create empty timeslot', text:'Create Empty Timeslot', icon:'calendar', onClick:this.handleCreateTimeslot})

    // If the item has errors, handle display changes for displaying error information
    let error = false
    let errorBtns = ''
    if(errors) {
      error = true
      let frontEnd = false
      let rearEnd = false
      let doesNotExist = false
      errors.forEach((err) => {
        switch(err.type) {
          case 'FRONT_DROP_OFF':
          case 'FRONT_END':
            frontEnd = true
            break
          case 'REAR_DROP_OFF':
          case 'REAR_END':
            rearEnd = true
            break
          case 'TOTAL_DROP_OFF':
          case 'TOTAL':
            frontEnd = true
            rearEnd = true
            break
          case 'FILE_DOES_NOT_EXIST':
            doesNotExist = true
            break
          default:
            break
        }
      })
      if(frontEnd || rearEnd) {
        let endTimeText = span.printEndTime({showMilliseconds: showSubseconds})
        timeLabel = [timeLabel,
            (<span key='dash'> - </span>),
            (<span key='end' className='endTimeLabel'>{endTimeText}</span>)]
        timeTitle = timeTitle + ' - ' + endTimeText
      }
      if(frontEnd) {
        className = className + ' frontError'
      }
      if(rearEnd) {
        className = className + ' rearError'
      }
      if(doesNotExist) {
        className = className + ' dneError'
        displayName = displayName + " (FILE DOES NOT EXIST!)"
      }

      errorBtns = (<ScheduleItemErrorButtons errors={errors}
          inBlock={inBlock}
          scheduleItemKey={scheduleItem.key}
          shiftItem={itemActions.shiftItem}
          itemBeShifted={itemActions.itemBeShifted}
          expandScheduleBlock={itemActions.expandScheduleBlock}
          removeScheduleItems={itemActions.removeScheduleItems}
          selectScheduleItems={selectScheduleItems}/>)
    }

    // Create icon for content window
    let contentWindowIcon = ''
    if(fileMetadata && ("content window open" in fileMetadata || "content window close" in fileMetadata)) {
      let willPlayNext = moment.isMoment(span.start) ?
        span.start.toDate() :
        span.start.closestDate(new Date(), {forceDirection: "after"})
      let contentWindowStatus = getContentWindowStatus(fileMetadata, willPlayNext.valueOf())
      let {closed, message} = contentWindowStatus
      if(closed) {
        contentWindowIcon = <Icon title={message} name='clock' color='red'/>
        // Show error indication for items whose content windows are closed.
        error = true
      }
    }

    // Add selected display class for selected items
    if(selected) {
      className = className + ' selected'
    }

    // Add blockSelected display class for items that are in a block and selected
    if(blockSelected) {
      className = className + ' blockSelected'
    }

    // Add formatting and icon if the item is currently playing
    let isPlayingIcon = null
    if(playingOn && playingOn.length) {
      className = className + ' isPlaying'
      isPlayingIcon = (
        <Popup header='Currently Playing on:'
          content={<List items={playingOn}/>}
          trigger={<Icon title='Now Playing' name='play'/>}/>
      )
    }

    // Get Duration
    let duration = null
    let durationNote = null
    let durationTitle = ''
    if(type !== "empty") {
      duration = millisToHourString(span.duration(), showSubseconds)
      durationTitle = `This slot is ${duration} long.`
      // Handle timeslot and item duration mismatch
      if(this.props["item duration"]) {
        let itemTime = (parseFloat(this.props["item duration"]) * 1000)
        let itemDuration = millisToHourString(itemTime, showSubseconds)
        if(itemDuration !== duration) {
          durationNote = ` (Content is ${itemDuration})`
          if(itemTime > span.duration()) {
            durationTitle = `${durationTitle} The content in this slot is ${itemDuration} long, so only part of it will play.`
          } else if(itemTime < span.duration()) {
            durationTitle = `${durationTitle} The content in this slot is only ${itemDuration} long, so it will play multiple times.`
          }
          // Add fit slot to item dropdown option
          let fitSlotOption = {
            key: "fit slot to item",
            text: "Fit Timeslot To Content",
            icon: (<Icon.Group className="icon">
              <Icon name="clock"/>
              <Icon name="cut" corner/>
            </Icon.Group>),
            onClick: this.handleFitSlotToItem
          }
          let changeDurationIndex = dropdownOptions.findIndex((option) => option.key === "change duration")
          if(changeDurationIndex === -1) {
            dropdownOptions.push(fitSlotOption)
          } else {
            dropdownOptions = [...dropdownOptions.slice(0, changeDurationIndex + 1), fitSlotOption, ...dropdownOptions.slice(changeDurationIndex + 1)]
          }
        }
      }
    }

    let extraLabel = null
    if(label) {
      extraLabel = (<Label color="blue" horizontal onClick={this.handleLabelClick}>{label}</Label>)
    }

    let itemStyle = {}
    let firstStyle = {}
    let lastStyle = {}
    if(!selected && !blockSelected && !error && blockColor) {
      let colors = /#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})/.exec(blockColor)
      if(colors) {
        firstStyle = {
          borderLeft: `4px solid ${blockColor}`
        }
        lastStyle = {
          borderRight: `4px solid ${blockColor}`
        }
        let [R, G, B] = colors.slice(1).map(color => {
          color = parseInt(color, 16)
          color = Math.floor(255 - (Math.sqrt(255 - color) * 2))
          return color.toString(16).padStart(2, '0')
        })
        itemStyle = {
          backgroundColor: `#${R}${G}${B}`
        }
      }
    }
    let selectBox = (<Table.Cell collapsing textAlign='center' onClick={this.handleSelect} style={firstStyle}>
      <Checkbox checked={selected}/>
    </Table.Cell>)
    if(type === "empty") {
      selectBox = (<Table.Cell collapsing style={firstStyle}/>)
    }

    return (
      <Table.Row className={className} error={error} tabIndex='0' onKeyUp={this.handleKeyShortcuts} style={itemStyle}>
        {selectBox}
        <Table.Cell textAlign='center' collapsing>
          <ScheduleItemDropdown options={dropdownOptions}
            fileType={fileType}
            handleSelect={this.handleDropdownSelect}
            handleDeselect={this.handleDropdownDeselect}
            scrollSize={scrollSize}/>
        </Table.Cell>
        <Table.Cell width={1}
          title={timeTitle}
          className='scheduleItemTime'
          onClick={this.handleTimeClick}
          textAlign="center">
          <span ref={this.setTimeRef}>{timeLabel}</span>
        </Table.Cell>
        <Table.Cell width={1}
          onClick={this.handleDurationClick}
          title={durationTitle}
          textAlign="center">
          <span className="scheduleItemDuration">
            {type !== "empty" ? <Icon name="clock"/> : ''}
            {duration}
          </span>
          {durationNote}
        </Table.Cell>
        <Table.Cell className='scheduleItemLabel'
          title={displayName}
          onClick={this.handleItemClick}
          width={10}
          style={lastStyle}>
          {isPlayingIcon}
          {fileIcon}
          {lockedIcon}
          {loopIcon}
          {contentWindowIcon}
          {scte35Label}
          {extraLabel}
          <span ref={this.setBodyRef}>{displayName}</span>
          {eventStatus}
          {errorBtns}
        </Table.Cell>
      </Table.Row>
    )
  }

}

export default ScheduleTableItem
