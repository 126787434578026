import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {actions} from 'redux/applications/about'
import {Tab} from 'semantic-ui-react'
import {version, SERVER_URL} from 'config'
import {staticAsset} from 'helpers/net_helpers'
import {AboutPageCastusMachineType, AboutPageCastusOtherInfo, AboutPageLicenseInfoGen, AboutPageVersionListGen} from 'v4/component.about.page'
import {getIn} from 'helpers/general_helpers'
import {COMPILE_DATE} from 'config'

import castusLogo from 'castuslogo.png'
import './About.css'
import 'v4.css'

class About extends PureComponent {

  constructor(props) {
    super(props)
    props.fetchAboutInformation();
  }

  render() {
    let {versions, license} = this.props

    let v4changelog = `${SERVER_URL}/quickroll4/development.html`
    let v5changelog = staticAsset("/development.html")

    let tabPanes = [
      {menuItem: "License", render: () => (
        <Tab.Pane className="AboutTabPane">
          {AboutPageLicenseInfoGen(license)}
        </Tab.Pane>
      )},
      {menuItem: "Versions", render: () => (
        <Tab.Pane className="AboutTabPane">
          {AboutPageVersionListGen(versions)}
        </Tab.Pane>
      )},
      {menuItem: "Version 5 Interface Changelog", render: () => (
        <Tab.Pane className="AboutTabPane">
          <iframe src={v5changelog} width="100%" title="v5changelog"></iframe>
        </Tab.Pane>
      )},
      {menuItem: "System Changelog", render: () => (
        <Tab.Pane className="AboutTabPane">
          <iframe src={v4changelog}
            title="Changelog"
            id="AboutChangelog"/>
        </Tab.Pane>
      )}
    ]

    let v5Version = version
    let buildType = getIn(versions, ["platform", "Build type"])
    if (!buildType || buildType === "" || (buildType && buildType.trim() === "release"))
      v5Version = `${version}`
    else
      v5Version = `${version} ${buildType.trim().toUpperCase()}`

    let cpu = getIn(versions, ["cpu"])
    if (!cpu) cpu = ""

    let kernel = getIn(versions, ["kernel"])
    if (!kernel) kernel = ""

    if (process.env.NODE_ENV === "production")
      { }
    else
      v5Version = `${v5Version} [dev mode]`

    /* FIXME: Tab inserts a tab header with a minHeight that screws with tab placement if the content
     *        (which is SCROLLABLE by the way!) exceeds the height of the available space below the tab bar.
     *        Not by much, but enough to shift the tab bar up a pixel or two! */
    return (
      <div id="AboutApplication">
        <div style={{position: "relative", top: "0", left: "0", height: "10.75em", minHeight: "10.75em" }}>
          <div style={{position: "absolute", left: "114px", top: "0px", paddingBottom: "0.5em"}}>
            <div id="AboutHeader" className="noselect">{AboutPageCastusMachineType(license)}</div>
            <div id="AboutCopyright">{AboutPageCastusOtherInfo({ver:v5Version,kernel:kernel,cpu:cpu,buildDate:COMPILE_DATE})}</div>
          </div>
          <img src={castusLogo} alt="Castus logo" draggable="false" style={{position: "absolute", left: "11px", top: "0px", userSelect: "none"}} />
        </div>
	<Tab panes={tabPanes} id="AboutTabs"/>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({...state.about})

const mapDispatchToProps = actions

export default connect(mapStateToProps, mapDispatchToProps)(About)
