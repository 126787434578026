
/* Sorry, React will not JSX without this */
/* our quick and dirty JSX transpiler will filter this out */
import React from 'react'
import { HTMLString } from 'v4/component.htmlstring';

const AboutPageCastusMachineType = (license) => {
	let type;

	if (license.quickcast)
		type = 'QuickCast';
	else
		type = 'QuickRoll';

	return <b><i>CASTUS</i> {type}</b>;
}

const AboutPageCastusOtherInfo = (info) => {
	let ver = info.ver || "";
	let cpu = info.cpu || "";
	let krn = info.kernel || "";
	let bdt = info.buildDate || "";
	return <span style={{userSelect: "all", cursor: "text"}}><span style={{fontSize: "1.75em", fontWeight: "bold"}}>Version {ver} {bdt}</span><br/><br/>&copy; 2007-2025 CASTUS Corporation, ALL RIGHTS RESERVED<br/>For more information visit <a href="http://castus.tv" className="about_link" target="_blank" rel="noopener noreferrer">castus.tv</a><br/>{krn}<br/>CPU is a {cpu}</span>;
}

const AboutPageLicenseInfoGen = (ref) => {
	let jsa = [ ];

	if (typeof(ref) == 'object') {
		if (ref.exists) {
			if (ref.expired)
				jsa.push(<b key="stat">License file has expired<br/></b>);
			else if (!ref.valid)
				jsa.push(<b key="stat">License file is not valid<br/></b>);
			else
				jsa.push(<b key="stat">License file valid<br/></b>);
		}
		else {
			jsa.push(<b key="stat">No license file assigned to this system<br/></b>);
		}

		if (ref.expiration !== undefined && ref.expiration !== null && ref.expiration !== '' && ref.expiration !== 0 && ref.expiration !== "0") /* <unix time> <ctime> */
			jsa.push(<span key="exp">License expires at: {ref.expiration.toString()}<br/></span>);
		if (ref.sd_channels > 0)
			jsa.push(<span key="sd">SD channels allowed: {ref.sd_channels}<br/></span>);
		if (ref.hd_channels > 0)
			jsa.push(<span key="hd">HD channels allowed: {ref.hd_channels}<br/></span>);
		if (ref.preview_channels > 0)
			jsa.push(<span key="prev">Preview channels allowed: {ref.preview_channels}<br/></span>);
		if (ref.audio_channels > 0)
			jsa.push(<span key="aud">Audio-only channels allowed: {ref.audio_channels}<br/></span>);
		if (ref.restricted_inputs > 0)
			jsa.push(<span key="rinput">R-inputs allowed: {ref.restricted_inputs}<br/></span>);
		if (ref.id !== '' && ref.id !== undefined)
			jsa.push(<span key="lid">License ID: {ref.id}<br/></span>);
		if (ref.encrypted_content !== '' && ref.encrypted_content !== undefined)
			jsa.push(<span key="enc">Encrypted content: {ref.encrypted_content}<br/></span>);
		if (ref.allow_schedules !== '' && ref.allow_schedules !== undefined)
			jsa.push(<span key="sched">Schedules allowed: {ref.allow_schedules}<br/></span>);
		if (ref.quickcast)
			jsa.push(<span key="quickcast">QuickCast unit<br/></span>);
	}
	else {
		jsa.push(<b key="stat">License information not available<br/></b>);
	}

	return <div style={{padding: "0.25em", boxSizing: "border-box", fontFamily: 'Lato,Arial,Helvetica,sans-serif', fontWeight: "300", userSelect: "all"}} className='about_listbox_table'>{jsa}</div>;
}

const AboutPageVersionListGen = (versions) => {
	const mkRevision = (v) => {
		/* convert raw string like "5.8.0.git.33.sha.be0c3abe" to just the version number and revision.
		 * Nathan doesn't like the "techie" version, of course, and for very understandable reasons. */
		let r = "";
		if (v.Revision) {
			let i=0,p=v.Revision.split(/\./);
			while (i < p.length && p[i].match(/^\d+$/)) i++;
			r += p.slice(0,i).join('.');
			if (i < p.length && p[i] === "UNKNOWN") {
				if (r !== "") r += " ";
				r += "(unknown)";
				i++;
			}
			if (i < p.length && p[i] === "git") {
				i++;
				if (i < p.length && p[i].match(/^\d+$/)) {
					r += " rev "+p[i];
					i++;
				}
			}

			if (v["Build type"] === "beta" || v["Build type"] === "alpha")
				r += " "+v["Build type"];
		}
		return r;
	};

	/* V5 does it best, copy into this UI */
	let softwareVersions = Object.entries(versions)
		.filter(([k,v]) => k.match(/\.version$/))
		.sort(([ak,av],[bk,bv]) => av.Name.localeCompare(bv.Name)).map(([k,v]) => {
		return (<tr key={k} style={{userSelect: "all"}}>
			<td>{v.Name}</td>
			<td style={{width: "25em"}}>{mkRevision(v)}</td>
			</tr>)
	});

	return <table className="about_listbox_table_versions"><tbody>{softwareVersions}</tbody></table>;
}

export { AboutPageCastusMachineType, AboutPageCastusOtherInfo, AboutPageLicenseInfoGen, AboutPageVersionListGen };

