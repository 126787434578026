import React, {PureComponent} from 'react'
import {getIn} from 'helpers/general_helpers'
import {Modal, TextArea, Menu} from 'semantic-ui-react'

import './AccessUrlsModal.css'

const OUTPUT_URLS = (id, settings) => {

  let acodec = getIn(settings, ['enc', '0', 'audio', 'encoder'])
  let host = `${document.location.protocol}//${document.location.host}`
  let acodec_ext = "mp3"

  if (acodec === "ac-3") acodec_ext = "ac3"
  else if (acodec === "" || acodec === "mp3") acodec_ext = "mp3"
  else acodec_ext = acodec

  let ipout = "";
  let ipamino = "";

  let ipproto = getIn(settings, ['enc', '0', 'iptv', 'ip_protocol'])
  let iptarget = getIn(settings, ['enc', '0', 'iptv', 'ip_target'])
  let iptargetport = getIn(settings, ['enc', '0', 'iptv', 'ip_target_port'])

  if ((ipproto && ipproto !== "" && ipproto !== "none") && (iptarget && iptarget !== "") && (iptargetport && iptargetport !== "")) {
    ipout = "\n" + ipproto + "://@" + iptarget + ":" + iptargetport;
    ipamino = "\n" + "igmp://" + iptarget + ":" + iptargetport;
  }

  id = id.replace(/ /g, '%20')
return `The IP streams from this output may be accessible from these URLs.

As MPEG-TS stream (if TS is enabled for the encoder):
${host}/quickroll4/stream_video.cgi/${id}/0.ts

As MPEG-TS stream over HLS (if TS and HLS is enabled for the encoder):
${host}/quickroll4/stream_video.cgi/${id}/0.m3u8

As MPEG-TS stream over HLS on set top boxes (if TS and HLS is enabled for the encoder):
${host}/quickroll4/stream%5Fvideo.cgi/${id}/0.m3u8

As MPEG program stream (if PS is enabled for the encoder):
${host}/quickroll4/stream_video.cgi/${id}/0.mpg

As FLV stream (if TS is enabled for the encoder):
${host}/quickroll4/stream_video.cgi/${id}/0.flv

As audio-only stream (if audio encoder enabled):
${host}/quickroll4/stream_audio.cgi/${id}/0.${acodec_ext}

IP output:${ipout}

IP output (Amino set top box):${ipamino}

To watch the stream, select the URL (starting from http://), copy, then paste the URL into your favorite media player (VLC player recommended).`
}

const INPUT_URLS = (id) => {
  let host = document.location.href
  id = id.replace(/ /g, '%20')
return `The IP streams from this output may be accessible from these URLs:

As MPEG-PS stream (ONLY if the source is an MPEG program stream):
${host}/quickroll4/stream_input.cgi/${id}/0.mpg

As MPEG-TS stream (ONLY if the source is an MPEG transport stream, or a SRT/HLS/RTMP/RTSP source):
${host}/quickroll4/stream_input.cgi/${id}/0.ts

As audio:
${host}/quickroll4/stream_input.cgi/${id}/0.mp3

As a still frame (at any time):
${host}/quickroll4/fetchimage.cgi?car=1&ear=1&kar=1&fmt=jpeg&en_webp=1&chss=4:4:4&live=1&f=/mnt/main/tv/inputs/${id}

To watch the stream, select the URL (starting from http://), copy, then paste the URL into your favorite media player (VLC player recommended).`
}

export default class AccessUrlsModal extends PureComponent {

  render() {
    let {id, type, settings} = this.props

    let text = ''
    switch(type) {
      case 'output':
        text = OUTPUT_URLS(id, settings ? settings : {})
        break;
      case 'input':
        text = INPUT_URLS(id)
        break;
      default:
        break;
    }

    return (
      <Modal trigger={<Menu.Item icon="share" content="Network Urls"/>} closeIcon>
        <Modal.Header>Network Access Urls for {id}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <TextArea id='AccessUrlsText' readOnly value={text}/>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }

}
